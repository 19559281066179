import React, { Fragment, PureComponent } from 'react';
import { CURRENCY_FORMAT, IS_AUTHENTICATED, SET_STORAGE, GET_STORAGE, klaviyoAddedToCart } from "../../Constants/AppConstants";
import ReactImageFallback from "react-image-fallback";
import $ from 'jquery';
import history from "../../history";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-photoswipe/lib/photoswipe.css';

class SingleCustomProductListGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            addToBundle: "Add to Bundle",
            alertMsg: false,
            items: [],
            options: {},
            isOpen: false,
            flavorId: '',
            variationId: '',
            isAuthenticated: IS_AUTHENTICATED(),
            user: {},
            addToBundleClass: '',
            productDetails: this.props.product,
            subscription: this.props.subscription,
            subscription_status: '',
            monthId: "1",
            newItem: [],
            isModalOpen: false,
            pendingItem: null,
            bundle: GET_STORAGE("Bundle") ? JSON.parse(GET_STORAGE("Bundle")) : [],
        }
    }

    componentDidMount() {
        const product = this.state.productDetails;

        if (product.flavors && product.flavors.length > 0) {
            this.setState({
                monthId: product.first_month,
                firstMonthflavorId: product.flavors[0].id,
            })
            this.activeFlavors(product);
        } else {
            this.setState({
                variationId: product.first_month,
                monthId: product.first_month,
            })
            setTimeout(() => {
                this.variation(this.state.variationId);
            }, 100)
            this.filterFlavors(this.state.subscription, product)
        }
    }

    variation = (variationKey) => {
        let flavorId = variationKey.split("_")[1];

        const product = this.state.productDetails;
        const variation = product.variations[variationKey];

        let regularPriceDisplay = false;
        let regular_price = 0;
        let sale_price = 0;
        if (variation) {
            if (parseFloat(variation.sale_price) > 0) {
                regularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }

            let weight_lb = 0.00;
            // if ((variation.dimensions) !== null) {
            //     weight_lb = variation.dimensions.weight_lb;
            // } else {
            //     weight_lb = 0.00;
            // }

            this.setState({
                productId: product.product_id,
                productName: product.title,
                productSKU: variation.sku,
                productDescription: product.short_description,
                productIsBundle: product.is_bundle,
                productWeightInlb: weight_lb,
                productSlug: product.slug,
                image: product.thumb_image,
                variationId: variation.variation_id,
                flavor_id: flavorId, 
                variationName: variation.variation_name,
                regularPriceDisplay: regularPriceDisplay,
                regularPrice: regular_price,
                salePrice: sale_price,
                discountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
                //subscription: this.state.subscription,
                stockStatus: variation.stock_status,
                inStock: parseInt(variation.quantity),
                quantity: 1,
                subscription_status: variation.subscription_status
            });
        }

    }

    activeFlavors = (product,) => {
        const monthIdAsInt = parseInt("1")
        const variationsForMonth = Object.values(product.variations)
            .filter(variation => variation.month == monthIdAsInt);
        const activeFlavorIds = variationsForMonth.map(variation => variation.flavor_id);
        const updatedFlavors = product.flavors.filter(flavor => activeFlavorIds.includes(flavor.id));
        let defaultFlavor = null;
        if (updatedFlavors.length > 0) {
            defaultFlavor = updatedFlavors.find(flavor => flavor.id == this.state.firstMonthflavorId) || updatedFlavors[0];
        }

        if (defaultFlavor) {
            this.setState({
                activeFlavors: updatedFlavors,
                firstMonthflavorId: defaultFlavor.id
            }, () => {
                this.filterFlavors(this.state.subscription, product, updatedFlavors)
            });
        }

    };

    filterFlavors = (subscription, product, activeFlavors) => {
        if (activeFlavors != undefined) {
            if (subscription == 'yes') {
                //filter inactive variations 
                const variationsWithSubscriptionStatus0 = Object.values(product.variations)
                    .filter(variation => (variation.subscription_status == "0" || variation.subscription_status == null));
                const updatedVariations = Object.fromEntries(
                    Object.entries(product.variations)
                        .filter(([key, variation]) => 
                            !variationsWithSubscriptionStatus0.some(toRemove => toRemove.variation_id == variation.variation_id)
                        )
                );
                
                //filter inactive flavors 
                const flavorIdsToRemove = variationsWithSubscriptionStatus0.map(variation => variation.flavor_id);
                const updatedFlavors = activeFlavors.filter(flavor => !flavorIdsToRemove.includes(flavor.id));
                this.setState({ productDetails: { ...product, flavors: updatedFlavors } });
                let defaultFlavor = null;

                if (updatedFlavors.length > 0) {
                    defaultFlavor = updatedFlavors.find(flavor => flavor.id === this.state.flavorId) || updatedFlavors[0];
                }
                //upadate variations and flavors 
                this.setState({
                    productDetails: {
                        ...product,
                        flavors: updatedFlavors,
                        variations: updatedVariations,
                        defaultFlavor: defaultFlavor,
                    },
                    activeFlavors: updatedFlavors
                }, () => {
                    if (defaultFlavor) {
                        this.setState({ firstMonthflavorId: defaultFlavor.id });
                    }
                });
                if (defaultFlavor) {
                    let variationKey = this.state.monthId + "_" + defaultFlavor.id;
                    this.variation(variationKey);
                }
            } else {
                this.setState({
                    productDetails: {
                        ...product,
                        flavors: this.state.activeFlavors,
                        variations: product.variations,
                        defaultFlavor: null,
                    },
                    activeFlavors: this.state.activeFlavors
                });
                let variationKey = this.state.monthId + "_" + this.state.firstMonthflavorId;
                this.variation(variationKey);
            }
        } else {
            if (subscription == 'yes') {
                const variationsWithSubscriptionStatus0 = Object.values(product.variations)
                    .filter(variation => variation.month == 1 && (variation.subscription_status == "0" || variation.subscription_status == null));
                    
                const updatedVariations = Object.fromEntries(
                    Object.entries(product.variations)
                        .filter(([key, variation]) => 
                            !variationsWithSubscriptionStatus0.some(toRemove => toRemove.variation_id == variation.variation_id)
                        )
                );
                this.setState({
                    productDetails: {
                        ...product,
                        variations: updatedVariations,
                    },
                });
            } else {
                this.setState({
                    productDetails: {
                        ...product,
                        variations: product.variations,
                    },
                });
            }

            this.variation(this.state.monthId);
        }
    }

    changeFirstMonthFlovour = (e) => {
        this.variation(1 + "_" + e.target.value);
        this.state.firstMonthflavorId = e.target.value;
    }

    quantityIncrement = (e) => {
        this.setState({ quantity: Number(this.state.quantity) + 1 })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: Number(this.state.quantity) - 1 })
    }
    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addToCartLabelChange = (e) => {
        this.setState({
            alertMsg: true,
            addToBundle: "Adding...",
            addToBundleClass: "added"
        })

        setTimeout(function () {
            this.setState({
                addToBundle: "Thank You",
                addToBundleClass: "added"
            })
        }.bind(this), 1000)

        setTimeout(function () {
            this.setState({
                addToBundle: "Add More ..."
            })
        }.bind(this), 2000)

        setTimeout(function () {
            this.setState({
                alertMsg: false,
            })
        }.bind(this), 5000)
    }

    handleCart = (e) => {
        history.push("/cart");
    }

    handleModalCancel = () => {
        this.setState({ isModalOpen: false, pendingItem: null });
    };
    
    handleModalOk = () => {
        const { pendingItem, bundle } = this.state;

        if (!pendingItem) {
            console.error("No item to confirm.");
            return;
        }

        const updatedBundle = [...bundle];
        let newItemCopy = { ...pendingItem };

        // Check and update bundle
        let itemUpdated = false;
        updatedBundle.forEach((item) => {
            if (item.cart_variation_id === pendingItem.cart_variation_id) {
                if (parseInt(item.quantity) >= parseInt(newItemCopy.in_stock)) {
                    alert("Out Of Stock");
                } else {
                    item.quantity += Number(newItemCopy.quantity);
                }
                itemUpdated = true;
                newItemCopy = null;
            }
        });

        if (newItemCopy !== null) {
            updatedBundle.push(newItemCopy);
        }

        SET_STORAGE("Bundle", JSON.stringify(updatedBundle));
        this.setState({ bundle: updatedBundle, isModalOpen: false, pendingItem: null });

        this.addToCartLabelChange();
        setTimeout(() => {
            this.props.updateAddedProducts(JSON.stringify(updatedBundle));
        }, 1500);

        this.getCart();
    };   
    
    addToBundle = (e, excludeDiscount) => {
        let bundle = [];
        let data = [];
        let discount_data_new = (GET_STORAGE("Discount_data")) ? JSON.parse(GET_STORAGE("Discount_data")) : '';

        if (!GET_STORAGE("Bundle")) {
            SET_STORAGE("Bundle", JSON.stringify(bundle));
        }
        bundle = JSON.parse(GET_STORAGE("Bundle"));

        let newItem = {
            cart_product_id: e.target.getAttribute('cart_product_id'),
            cart_product_name: e.target.getAttribute('cart_product_name'),
            cart_product_sku: e.target.getAttribute('cart_product_sku'),
            cart_product_description: e.target.getAttribute('cart_product_description'),
            cart_product_isbundle: e.target.getAttribute('cart_product_isbundle'),
            cart_product_weight_lb: e.target.getAttribute('cart_product_weight_lb'),
            cart_product_slug: e.target.getAttribute('cart_product_slug'),
            cart_image: e.target.getAttribute('cart_image'),
            cart_variation_id: e.target.getAttribute('cart_variation_id'),
            cart_flavor_id: e.target.getAttribute('cart_flavor_id'),
            cart_variation_name: e.target.getAttribute('cart_variation_name'),
            cart_sale_price: e.target.getAttribute('cart_sale_price'),
            subscription: e.target.getAttribute('subscription'),
            cart_subscription_msg: e.target.getAttribute('cart_subscription_msg'),
            cart_discount_price: e.target.getAttribute('cart_discount_price'),
            quantity: parseInt(e.target.getAttribute('quantity')),
            in_stock: parseInt(e.target.getAttribute('in_stock')),
            regular_price: parseInt(e.target.getAttribute('regular_price')),
            discount_data_new: discount_data_new,
            custom_bundle_discount_price: e.target.getAttribute('cart_sale_price'),
            custom_bundle_discount: 0,
            subscription_status: e.target.getAttribute('subscription_status'),
            activeFlavors: this.state.activeFlavors, // Add ActiveFlavors to the newItem
            productInfo: this.state.productDetails   // Add productInfo to the newItem
        }
    
        if (excludeDiscount == "excludeDiscount") {
            this.setState({ isModalOpen: true, pendingItem: newItem });
        }else {
            if (bundle) {
                bundle.forEach((item) => {
                    if ((item.cart_variation_id == e.target.getAttribute('cart_variation_id'))) {
                        if (parseInt(item.quantity) >= parseInt(e.target.getAttribute('in_stock'))) {
                            alert("Out Of Stock") // Check product quantity
                        } else {
                            item.quantity = Number(item.quantity) + Number(newItem.quantity);
                        }
                        data.push(item);
                        newItem = null;
                    } else {
                        data.push(item);
                    }
                });
                if (newItem != null) {
                    data.push(newItem);
                }
            } else {
                data.push(newItem);
            }

            SET_STORAGE("Bundle", JSON.stringify(data));
            this.addToCartLabelChange();
            setTimeout(() => {
                this.props.updateAddedProducts(JSON.stringify(data));
            }, 1500);
            this.getCart();
        }
    }

    getCart = () => {

        if (GET_STORAGE("Bundle")) {
            this.setState({ AddedProducts: JSON.parse(GET_STORAGE("Bundle")) });
        } else {
            this.setState({ AddedProducts: [] });
        }
    };

    render() {

        const product = (this.state.productDetails.variations && Object.keys(this.state.productDetails.variations).length != 0) ? this.state.productDetails : '';
        let productExistsInBundle = false;
        const bundle = GET_STORAGE("Bundle") ? JSON.parse(GET_STORAGE("Bundle")) : '';
        if (bundle && bundle.length > 0) {
            productExistsInBundle = bundle.some(item => item.cart_product_id == product.product_id);
        }
        const ActiveFirstMonthFlavors = this.state.activeFlavors;
        const { firstMonthflavorId } = this.state;

        if (!product || Object.keys(product).length === 0) {
            return null;
        }
        return (
            <Fragment>
                <div className="col-md-4">
                    <div className="refer_produc_wrapper sup_pro_col h-100 d-flex flex-column align-items-center">
                        <div className="refer-img-wrapper">
                            <ReactImageFallback
                                src={product.hasOwnProperty('list_image') ? product.list_image : ''}
                                fallbackImage={require('../../Assets/images/preloader.gif')}
                                initialImage={require('../../Assets/images/preloader.gif')}
                                alt={product.hasOwnProperty('title') ? product.title : ''}
                                className="img-fluid" />
                        </div>
                        <div className="refer_product_short_desc w-100 text-left">
                            <h3 className="font_20 text_black fw_el_bold mb_6 text-center">{product.hasOwnProperty('title') ? product.title : ''}</h3>
                            <span className="font_16 d-block text_black fw_ar_bold text-center">{CURRENCY_FORMAT(this.state.salePrice)}</span>
                            {
                                  (this.state.regularPrice != "0" && (this.state.regularPrice != this.state.salePrice)) ?
                                    <span className="font_12 mb_14 d-block items_text fw_ar_reg text-center line_through">{CURRENCY_FORMAT(this.state.regularPrice)}</span>

                                    : <span className="font_12 mb_14 d-block items_text fw_ar_reg"></span>
                            }
                            <div className={`cart_quantity quan_cart_blk w-100 custom_bundle_qty mb_14 ${(ActiveFirstMonthFlavors !== undefined) ? 'quatitywithFla' : ''}`}>
                                {(product.variation_status !== "0" && product.product_status !== "0" && this.state.product_status != "0") ?
                                    <div className='qunatity_blk'>
                                        <h5 className="mb_4 font_13 items_text fw-reg">Quantity</h5>
                                        <div data-title="Quantity" className="input-group refer_product_input_group">
                                            <div className="input-group-prepend">
                                                <button type="button" onClick={this.quantityDecrement} disabled={(this.state.quantity <= 1) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-minus"></i></button>
                                            </div>
                                            <input onChange={this.changeHandler} value={this.state.quantity} key={Math.random()} name="quantity[]" type="text" readOnly className="text-center font_13 p-0 form-control form-control-sm product_quantity_content refer_product_quantity_content" step="1" min="0" max="" title="Qty" size="4" pattern="[0-9]*" inputMode="numeric" />
                                            <div className="input-group-prepend">
                                                <button type="button" onClick={this.quantityIncrement} disabled={(parseInt(this.state.quantity) >= parseInt(product.in_stock)) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    : " "
                                }
                                {(ActiveFirstMonthFlavors !== undefined) ? (
                                    <div className="flavour_blk">
                                        <h3 className="mb_4 font_13 items_text fw-reg">Flavor</h3>
                                        <select onChange={this.changeFirstMonthFlovour} name="flovour" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_reg" value={firstMonthflavorId}>
                                            {(ActiveFirstMonthFlavors !== undefined) ?
                                                ActiveFirstMonthFlavors.map(function (flavor, key) {
                                                    return (
                                                        <option key={key} value={flavor.hasOwnProperty('id') ? flavor.id : null}>{flavor.hasOwnProperty('value') ? flavor.value : null}</option>
                                                    )
                                                }.bind(this)) : null
                                            }
                                        </select>
                                    </div>
                                ) : null}
                            </div>
                            <div className="quan_cart_blk  add_to_bundle">
                                {this.state.inStock <= 0 ? (
                                    <input name="addToCart" value="Out Of Stock" className="cart_add_product_btn stockout-btn disable" type="button" />
                                ) : (
                                    <input
                                        onClick={(e) => {
                                            if (this.state.productId == 48) {
                                                this.addToBundle(e, 'excludeDiscount');
                                            } else {
                                                this.addToBundle(e);
                                            }
                                        }}
                                        cart_product_id={this.state.productId}
                                        cart_product_name={this.state.productName}
                                        cart_product_sku={this.state.productSKU}
                                        cart_product_description={this.state.productDescription}
                                        cart_product_isbundle={this.state.productIsBundle}
                                        cart_product_weight_lb={this.state.productWeightInlb}
                                        cart_product_slug={this.state.productSlug}
                                        cart_image={this.state.image}
                                        cart_variation_id={this.state.variationId}
                                        cart_flavor_id ={this.state.flavor_id}
                                        cart_variation_name={this.state.variationName}
                                        cart_sale_price={this.state.salePrice}
                                        // subscription={this.state.subscription}
                                        // cart_subscription_msg={this.state.subscriptionMsg}
                                        cart_discount_price={this.state.discountPrice}
                                        quantity={this.state.quantity}
                                        in_stock={this.state.inStock}
                                        regular_price={this.state.regularPrice}
                                        subscription_status={this.state.subscription_status}
                                        value={this.state.addToBundle} name="addToBundle" className={`cart_add_product_btn ${this.state.addToBundleClass}`} type="button" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isModalOpen && (
                    <div className="modal fade show" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header cus-modal-header">
                                    <button type="button" className="close" onClick={this.handleModalCancel}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body1 montserrat">
                                    <p className="font_16 text_black fw_ar_reg m-0">
                                        Available for purchase only, product does not qualify for discount.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn mr-4 btn-close" onClick={this.handleModalCancel} type="button">
                                        Cancel
                                    </button>
                                    <button type="button" onClick={this.handleModalOk} className="cus_button">
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default SingleCustomProductListGrid;
